import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  AddressInput,
  AuthPayload,
  BanksInput,
  Calculator,
  DebtSettlementAccount,
  FinanceApplication,
  FinanceApplicationStatus,
  MaritalStatusInput,
  UpdateFinanceApplicationInput,
  VehicleModelPayload,
  useAddFinaceApplicationMutation,
  useGetInsuranceEstimatedCostMutation,
  useSaveUserAdressMutation,
  useUpdateBanksMutation,
  useUpdateFinaceApplicationMutation,
  useUpdateMaritalStatusMutation
} from '../../generated'
import { useBrowserStorage } from '../../hooks'
import {
  FinanceDetails,
  capitalize,
  genderToGenderData,
  getMakeAndModelCodes,
  getPhoneNumber
} from '../../utils'
import { IN_PROGRESS_FINANCE_APPLICATION_KEY } from '../../utils/constants'
import { useAuth } from '../AuthProvider'

export type FormSelectionData = {
  value: string
  label: string
}

export type PaymentType = 'IFC' | 'IFF'
type MartitalStatusType = 'divorced' | 'married' | 'unmarried' | 'widowed'
type NakedMaritalStatus = { [key in MartitalStatusType]: string }

const nakedMaritalStatus: NakedMaritalStatus = {
  divorced: 'DIVORCED',
  married: 'MARRIED',
  unmarried: 'SINGLE',
  widowed: 'SINGLE'
}

const SOURCE_CODES = process.env.VITE_WES_BANK_API_SOURCE_CODE?.split(':')
const DEFAULT_PRICE = 0

/**
 * The UserDataProviderProps type is an object with the following properties: monthlyIncome, banks,
 * saveIncomeData, getIncomeData, employed, updateEmployemntType, getBanks, addBanks, affordableAmount,
 * affordabilityError, vafStatus, saveUserAddress, fetchMMYData, mmyData, vehicleMakes, vehiclesModels,
 * makeModels, updateMakeModel, mmCode, updateMMcode, makesCategoryData, updateMaritalStatus.
 * @property {Calculator} monthlyIncome - Calculator
 * @property banks - Array<Banks>
 * @property {boolean} employed - boolean - This is the employment status of the user.
 * @property updateEmployemntType - This is a function that updates the employment type of the user.
 * @property getBanks - This is a function that fetches the banks from the API.
 * @property addBanks - This is a function that takes an array of BanksInput and returns a promise.
 * @property {string} affordableAmount - The amount that the user can afford to spend on a car.
 * @property {string} vafStatus - This is the status of the VAF. It can be either "pending",
 * "approved", or "rejected".
 * @property saveUserAddress - This is a function that saves the user's address.
 * @property fetchMMYData - This is a function that fetches the make, model and year data from the API.
 * @property {string} mmyData - This is the data that is returned from the API call to fetchMMYData.
 * @property vehicleMakes - Array<FormSelectionData>
 * @property vehiclesModels - Map<String, Array<MmAllSpecs>>
 * @property {string} mmCode - This is the code for the make and model of the vehicle.
 * @property updateMMcode - (input: string) => void
 * @property makesCategoryData - Map<String, Array<MmAllSpecs>>
 * @property updateMaritalStatus - (input: MaritalStatusInput) => Promise<void>
 */
type UserDataProviderProps = {
  monthlyIncome: Calculator
  banks: Array<FormSelectionData>
  employed: boolean
  updateEmployemntType: (value: boolean) => void
  getBanks: () => Promise<void>
  addBanks: (inputs: Array<BanksInput>) => Promise<void>
  affordableAmount?: string
  vafStatus: string
  saveUserAddress: (input: AddressInput) => Promise<boolean>
  mmyData: string
  vehicleMakes: Array<FormSelectionData>
  makesCategoryData: Map<string, Array<VehicleModelPayload>>
  updateMaritalStatus: (input: MaritalStatusInput) => Promise<void>
  financeData: string
  vehicleType: string
  preApprovalId: string
  newUsedVehicle: string
  updateNewUsedVehicle: (newUsed: string) => void
  mmCodeError: string
  saveProgress: () => void
  progressData: () => string | null | undefined
  clearProgress: () => void
  financeDetails?: FinanceDetails
  paymentMode: PaymentType
  setPaymentMode: (value: PaymentType) => void
  persistProgress: (data: UpdateFinanceApplicationInput) => Promise<void>
  persistedProgress: FinanceApplication
  setPersistedProgress: React.Dispatch<React.SetStateAction<FinanceApplication | undefined>>
  removePersistedProgress: () => void
  debtSettlementAccounts: DebtSettlementAccount[]
  setDebtSettlementAccounts(debtSettlementAccounts: DebtSettlementAccount[]): void
  debtSettlementRequestBody: string
  preApprovalRequestBody: string
  setFinanceData: (data: string) => void
  financeRequestBody: string
  nakedInsuranceRequestBody: string
  updatePreApprovalId: (payload: string) => void
  fetchInsuranceEstimatedCost: () => Promise<void>
  loadIndicativeQuote: boolean
  setAffordableAmount: (amount: string) => void
  setFinanceDetails: (financeDetails: FinanceDetails) => void
  setVafStatus: (status: string) => void
}

const UserDataContext = React.createContext<Partial<UserDataProviderProps>>({})

/**
 * It returns the value of the UserDataContext.Provider component
 */
export const useData = () => React.useContext(UserDataContext)

export default function UserDataProvider({
  children
}: {
  children: React.ReactElement
}): React.ReactElement {
  const { user: authUser } = useAuth()

  const [banks, setBanks] = React.useState<Array<FormSelectionData>>([])
  const [employed, setEmployment] = React.useState<boolean>(true)
  const [addBanksMutation] = useUpdateBanksMutation()
  const [affordableAmount, setAffordableAmount] = React.useState<string | undefined>()
  const [vafStatus, setVafStatus] = React.useState<string>('')
  const [saveUserAddressMutation] = useSaveUserAdressMutation()
  const [updateMaritalStatusMutation] = useUpdateMaritalStatusMutation()
  const [financeData, setFinanceData] = React.useState<string>('')
  const [preApprovalId, setPreApprovalId] = React.useState<string>('')
  const [newUsedVehicle, setNewUsedVehicle] = React.useState<string>('N')
  const [financeDetails, setFinanceDetails] = React.useState<FinanceDetails>()
  const [paymentMode, setPaymentMode] = React.useState<PaymentType>('IFC')
  const [debtSettlementAccounts, setDebtSettlementAccounts] = React.useState<
    DebtSettlementAccount[]
  >([])

  const [sessionUser] = useBrowserStorage<AuthPayload>('vw_User', 'session')

  const [localPersistedProgress, setLocalPersistedProgress, removePersistedProgress] =
    useBrowserStorage<FinanceApplication>(IN_PROGRESS_FINANCE_APPLICATION_KEY, 'session')

  const [persistedProgress, setPersistedProgress] = React.useState(
    localPersistedProgress ?? undefined
  )

  const [updateFinaceApplication] = useUpdateFinaceApplicationMutation({
    onCompleted: (res) => {
      setPersistedProgress(res.updateFinaceApplication as FinanceApplication)
    }
  })

  const [addFinanceApplication] = useAddFinaceApplicationMutation({
    onCompleted: (res) => {
      setPersistedProgress(res.addFinaceApplication as FinanceApplication)
    }
  })

  const persistProgress = async (data: UpdateFinanceApplicationInput) => {
    if (persistedProgress) {
      await updateFinaceApplication({
        variables: {
          input: {
            id: persistedProgress.id,
            progress: data.progress,
            isCompleted: data.isCompleted,
            status: data.status
          }
        }
      })
    } else {
      await addFinanceApplication({
        variables: {
          input: {
            isCompleted: data.isCompleted,
            progress: data.progress
          }
        }
      })
    }
  }

  React.useEffect(() => {
    setLocalPersistedProgress(persistedProgress)
  }, [persistedProgress])

  const userProfile = React.useMemo(() => {
    const address = persistedProgress?.progress?.[2]
    const employmentDetails = persistedProgress?.progress?.[4]
    const maritalStatus = persistedProgress?.progress?.[2]
    const user = authUser ?? sessionUser?.user
    const idNumber = user?.idNumber ?? sessionUser?.user?.idNumber
    const passportNumber = user?.passportNumber ?? sessionUser?.user?.passportNumber
    const firstName = user?.name ?? sessionUser?.user?.name
    const lastName = user?.surname ?? sessionUser?.user?.surname
    return {
      title: genderToGenderData(user?.gender ?? 'Male')?.title,
      firstName: firstName?.replace(/\s+/g, ''),
      lastName: lastName?.replace(/\s+/g, ''),
      gender: genderToGenderData(user?.gender ?? sessionUser?.user?.gender ?? 'Male')?.gender,
      ...(passportNumber && { dateOfBirth: user?.dob }),
      identification: {
        idNumber: idNumber ?? passportNumber,
        idType: idNumber ? 'I' : 'P'
      },
      postalAddress: {
        postalLine1: address?.addressLine1 ?? '30 SERENGATI SANDS POSTAL',
        suburb: address?.suburb ?? 'IKAGENG',
        postalCode: address?.postalCode.substring(0, 4) ?? '1709'
      },
      residentialAddress: {
        street: address?.addressLine1 ?? 'NAIVASHA',
        suburb: address?.suburb ?? 'IKAGENG',
        postalCode: address?.postalCode.substring(0, 4) ?? '2194',
        durationMonth: address?.timeOfLivingMonths,
        durationYear: address?.timeOfLivingYears
      },
      occupation: {
        customerType: '01',
        durationMonth: employmentDetails?.employmentTimeInMonths,
        durationYear: employmentDetails?.employmentTimeInYears
      },
      maritalStatus: maritalStatus?.status?.[0].toUpperCase() ?? 'M',
      contactMethod: {
        email: user?.email ?? sessionUser?.user?.email,
        cellPhone: getPhoneNumber(user?.phoneNumber ?? sessionUser?.user?.phoneNumber ?? '')
      }
    }
  }, [persistedProgress, authUser])

  const preApprovalRequestBody = React.useMemo(() => {
    const incomeDetails = persistedProgress?.progress?.[0]
    return {
      requestHeader: {
        requestDateTime: new Date().toISOString(),
        sourceId: SOURCE_CODES?.[0],
        correlationID: uuidv4()
      },
      customerProfile: userProfile,
      income: {
        netIncome: incomeDetails?.monthlyNet,
        grossIncome: incomeDetails?.monthlyGross
      },
      expenses: {
        totalMonthlyExpenses: incomeDetails?.monthlyExpenses
      },
      preApproval: {
        preApprovalType: 'A'
      },
      asset: {
        buyingType: 'D'
      },
      dealerExtras: []
    }
  }, [persistedProgress])

  const financeRequestBody = React.useMemo(() => {
    const user = authUser ?? sessionUser?.user
    const incomeDetails = persistedProgress?.progress?.[0]
    const occupationData = persistedProgress?.progress?.[4]
    const maritalStatus = persistedProgress?.progress?.[3]
    const address = persistedProgress?.progress?.[2]
    const financeData =
      persistedProgress?.progress?.financeData &&
      JSON.parse(persistedProgress?.progress?.financeData)
    return {
      requestHeader: {
        requestDateTime: new Date().toISOString(),
        sourceId: SOURCE_CODES?.[0],
        correlationID: uuidv4()
      },
      customerProfile: {
        ...userProfile,
        maritalStatus: undefined,
        nationality: 'ZA',
        countryOfBirth: 'ZA',
        ethnicGroup: '1',
        customerType: '01',
        bankingDetails: {
          finantialInstitutionCode: incomeDetails?.banksWith?.value,
          accountType: 'C'
        },
        occupation: {
          occupationTypeCode: occupationData?.occupation?.value,
          industryTypeCode: occupationData?.industry?.value,
          employersName: occupationData?.companyName,
          employerTypeCode: occupationData?.industryCategory?.value,
          yearsAtWork:
            (occupationData?.employmentTimeInYears ?? 0) > 0
              ? occupationData?.employmentTimeInYears
              : 1,
          workPhone: getPhoneNumber(user?.phoneNumber ?? sessionUser?.user?.phoneNumber ?? '')
        },
        maritalInformation: {
          maritalStatus: maritalStatus?.status?.[0].toUpperCase(),
          maritalContractCode:
            maritalStatus?.status === 'married' && maritalStatus?.contractType !== 'NA'
              ? maritalStatus?.contractType
              : undefined
        },
        residentialAddress: {
          careOfAddressLine: address?.addressLine1,
          street: address?.addressLine1,
          suburb: address?.suburb,
          postalCode: address?.postalCode.substring(0, 4),
          yearsAtResidence: (address?.timeOfLivingYears ?? 0) > 0 ? address?.timeOfLivingYears : 1,
          monthsAtResidence: address?.timeOfLivingMonths
        },
        postalAddress: undefined,
        contactMethod: {
          email: user?.email ?? sessionUser?.user?.email
        }
      },
      income: {
        netIncome: incomeDetails?.monthlyNet,
        grossIncome: incomeDetails?.monthlyGross
      },
      expenses: {
        totalMonthlyExpenses: incomeDetails?.monthlyExpenses
      },
      asset: {
        mmcode: occupationData?.asset?.mmcode,
        year: occupationData?.asset?.year,
        price: occupationData?.asset?.price,
        dealerCode: process.env.VITE_VAF_DEALER,
        buyingType: 'D',
        newUsed: occupationData?.asset?.newUsed ?? 'N',
        ...getMakeAndModelCodes(occupationData?.asset?.mmcode ?? '3215355')
      },
      financeIdentifier: {
        preApprovalId: `${financeData?.preApprovalId}`
      }
    }
  }, [persistedProgress])

  const nakedInsuranceRequestBody = React.useMemo(() => {
    const user = authUser ?? sessionUser?.user
    const incomeDetails = persistedProgress?.progress?.[0]
    const vehicleDetails = persistedProgress?.progress?.[1]
    const maritalStatus = persistedProgress?.progress?.[3]
    const address = persistedProgress?.progress?.[2]
    return {
      locale: {
        currency: 'ZAR',
        country: {
          countryName: 'SOUTH_AFRICA',
          countryCode: 'ZAF'
        }
      },
      user: {
        email: user?.email,
        financialInformation: {
          monthlyDisposableIncome: {
            valueInCents: incomeDetails?.monthlyGross
          },
          monthlyExpenses: {
            valueInCents: incomeDetails?.monthlyExpenses
          },
          monthlyNetIncome: {
            valueInCents: incomeDetails?.monthlyNet
          }
        },
        firstName: user?.name,
        lastName: user?.surname,
        maritalStatus: nakedMaritalStatus[maritalStatus?.status as MartitalStatusType],
        mobileNumber: user?.phoneNumber,
        nationality: {
          countryCode: 'ZAF',
          countryName: 'SOUTH_AFRICA'
        },
        identification: {
          idType: 'SA_ID',
          idNumber: user?.idNumber
        }
      },
      item: {
        overnightAddress: {
          googlePlaceId: 'ChIJl6QSijwJvh4ROlOYBA4nMXQ',
          addressText: address?.addressLine1,
          postalCode: address?.postalCode,
          geoCoordinates: {
            lng: 27.2487922,
            lat: -25.6968148
          }
        },
        vehicleIdentifiers: {
          make: vehicleDetails?.make,
          registrationYear: Number(vehicleDetails?.year),
          mmCode: Number(vehicleDetails?.mmCode),
          model: vehicleDetails?.model
        },
        vehicleUsage: 'PRIVATE',
        vehicleValueExcludingExtras: {
          value: {
            valueInCents: Number(vehicleDetails?.purchasePrice)
          },
          valueBasis: 'TRADE'
        }
      }
    }
  }, [persistedProgress])

  const [getInsuranceEstimatedCost, { loading: loadIndicativeQuote }] =
    useGetInsuranceEstimatedCostMutation({
      onCompleted: async (res) => {
        await persistProgress?.({
          id: persistedProgress?.id as string,
          status: FinanceApplicationStatus.Pending,
          isCompleted: false,
          progress: {
            ...persistedProgress?.progress,
            naked: res.getInsuranceEstimatedCost
          }
        })
      }
    })

  const fetchInsuranceEstimatedCost = async () => {
    const user = authUser ?? sessionUser?.user
    if (user) {
      getInsuranceEstimatedCost({
        variables: {
          input: {
            userId: user?.id as string,
            body: JSON.stringify(nakedInsuranceRequestBody)
          }
        }
      })
    }
  }

  const debtSettlementRequestBody = React.useMemo(() => {
    const incomeDetails = persistedProgress?.progress?.[0]
    const user = authUser ?? sessionUser?.user
    return JSON.stringify({
      requestHeader: {
        requestDateTime: new Date().toISOString(),
        sourceId: SOURCE_CODES?.[0],
        correlationID: uuidv4()
      },
      customerProfile: {
        firstName: user?.name?.replace(/\s+/g, ''),
        lastName: user?.surname?.replace(/\s+/g, ''),
        customerType: '01',
        dateOfBirth: user?.dob,
        gender: null,
        identification: {
          idNumber: user?.idNumber ?? user?.passportNumber,
          idType: 'I'
        }
      },
      preApproval: {
        netIncome: incomeDetails?.monthlyNet ?? 190000,
        grossIncome: incomeDetails?.monthlyGross ?? 210000,
        price: DEFAULT_PRICE,
        preApprovalId: null
      }
    })
  }, [persistProgress])

  const updateEmployemntType = (value: boolean) => setEmployment(value)

  /**
   * The function saves data to local storage.
   * @param {string} data - The `data` parameter is a string that represents the progress data that needs
   * to be saved in the browser's local storage. The `saveProgress` function is an asynchronous function
   * that takes this `data` parameter and saves it in the local storage with the key 'progress'.
   */
  const saveProgress = async () => {
    setLocalPersistedProgress(persistedProgress)
  }

  /**
   * The function retrieves progress data from local storage.
   */
  const progressData = () => localStorage.getItem('progress')

  /**
   * The function clears the progress data stored in the browser's local storage.
   */
  const clearProgress = () => localStorage.removeItem('progress')

  /**
   * It checks if the banks array is empty, if it is, it imports the financialInstitutionCode.json file,
   * parses it, and sets the banks array to the parsed data
   * @returns const getBanks = async () => {
   *     if (banks.length > 1) return
   *     const financialInstitutionData = await import(
   *       '../../../../../packages/database/prisma/seeds/financialInstitutionCode.json'
   *     )
   */
  const getBanks = async () => {
    if (banks.length > 1) return
    const financialInstitutionData = await import(
      '../../assets/seeds/financialInstitutionCode.json'
    )

    const financialInstitutionArrayData = JSON.parse(`${financialInstitutionData.default}`)
    const formValues = financialInstitutionArrayData.map((item: never) => ({
      label: capitalize(`${item['BANK_NAME']}`.replace(/"/g, '')),
      value: item['code']
    }))
    /* Setting the banks state to the filtered formValues. */
    setBanks(() => formValues.filter((item: FormSelectionData) => item.label !== 'undefined'))
  }

  /**
   * It takes an array of objects, and for each object in the array, it runs a mutation
   * @param inputs - Array<BanksInput>
   */
  const addBanks = async (inputs: Array<BanksInput>) => {
    await Promise.all([...inputs.map((input) => addBanksMutation({ variables: { input } }))])
  }

  /**
   * It takes an input of type AddressInput, and then it tries to save the user's address. If it
   * succeeds, it navigates to the next page. If it fails, it throws an error
   * @param {AddressInput} input - AddressInput
   */
  const saveUserAddress = async (input: AddressInput) => {
    try {
      const { data } = await saveUserAddressMutation({ variables: { input } })
      if (data?.saveUserAdress) {
        /// navigate('/auth/marital-status')
        return true
      }
    } catch (error) {
      throw error
    }
    return false
  }

  /**
   * It takes a MaritalStatusInput object as an argument, and then it calls the
   * updateMaritalStatusMutation mutation with the input object as the variables
   * @param {MaritalStatusInput} input - MaritalStatusInput
   */
  const updateMaritalStatus = async (input: MaritalStatusInput) => {
    try {
      const { data } = await updateMaritalStatusMutation({ variables: { input } })
      if (!data?.updateMaritalStatus) {
        throw new Error('We could not process the provided data, please try again!')
      }
    } catch (error) {
      throw error
    }
  }

  /**
   * It takes a string as an argument, parses it into JSON, and then sets the state of the preApprovalId
   * variable to the value of the preApprovalId key in the JSON object
   * @param {string} payload - The payload is the data that is returned from the payment gateway.
   */
  const updatePreApprovalId = (payload: string) => {
    const data = JSON.parse(payload)
    setPreApprovalId(data.preApprovalId)
  }

  const updateNewUsedVehicle = (input: string) => {
    setNewUsedVehicle(() => input)
  }

  const dataParams = {
    banks,
    employed,
    updateEmployemntType,
    getBanks,
    addBanks,
    affordableAmount,
    vafStatus,
    saveUserAddress,
    updateMaritalStatus,
    financeData,
    preApprovalId,
    updateNewUsedVehicle,
    newUsedVehicle,
    saveProgress,
    progressData,
    clearProgress,
    financeDetails,
    paymentMode,
    setPaymentMode,
    persistedProgress,
    persistProgress,
    setPersistedProgress,
    removePersistedProgress,
    debtSettlementAccounts,
    setDebtSettlementAccounts,
    debtSettlementRequestBody,
    setFinanceData,
    financeRequestBody: JSON.stringify(financeRequestBody),
    nakedInsuranceRequestBody: JSON.stringify(nakedInsuranceRequestBody),
    preApprovalRequestBody: JSON.stringify(preApprovalRequestBody),
    updatePreApprovalId,
    fetchInsuranceEstimatedCost,
    loadIndicativeQuote,
    setAffordableAmount,
    setFinanceDetails,
    setVafStatus
  }
  return <UserDataContext.Provider value={dataParams}>{children}</UserDataContext.Provider>
}
