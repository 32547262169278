import { ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DataInfoCard from '../DataInfoCard'
import DocumentsUploadModal from '../DocumentsUploadModal'
import InfoRowCard from '../InfoRowCard'

type CarData = {
  financeAmount: string
  date: number
  approval: boolean
  make: string
  model: string
  referenceNumber?: string
}

const CarCard = ({
  financeAmount,
  date,
  approval,
  make,
  model,
  referenceNumber
}: CarData): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [fullDate, setFullDate] = useState<any>()
  useEffect(() => {
    console.log(date)
    const newDate = new Date((date * 10) / 10) //don't know why I have to do this but it doesn't work otherwise.
    setFullDate(
      newDate.toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric' })
    )
  }, [])
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <DataInfoCard vehicle={`${make} ${model}`} approval={approval} width={[400, 600, 768]} py={2}>
      <Flex justify="space-between">
        <Flex
          data-testid={`dashboard-vehicle-${make}`}
          padding="16px"
          flexDirection="column"
          gap="4px"
          alignItems="flex-start"
        >
          <InfoRowCard label="Vehicle Make" value={make} />
          <InfoRowCard label="Vehicle Model" value={model} />
          <InfoRowCard label="Finance Amount" value={financeAmount} />
          <InfoRowCard label="Application Date" value={fullDate} />
        </Flex>
        <Flex justifyContent="flex-end" flexDirection="column" padding={4}>
          <Button
            data-testid="dashboard-link"
            rightIcon={<ChevronRightIcon />}
            variant="outline"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            onClick={onOpen}
          >
            Upload Documents
          </Button>
          <DocumentsUploadModal
            isOpen={isOpen}
            onClose={onClose}
            referenceNumber={referenceNumber}
          />
        </Flex>
      </Flex>
    </DataInfoCard>
  )
}

export default CarCard
