import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Link,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper as Steps,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { FiDroplet } from 'react-icons/fi'
import { usePreApprovalSteps } from '../../context/PreApprovalStepsProvider'
import VWModal from '../Modal'

export default function PreApprovalStepper() {
  const { currentStep, setCurrentStep, steps, isStepLoading } = usePreApprovalSteps()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const AffordabilityDescription = (
    <>
      <Text fontSize={'1.6rem'} fontWeight="bold">
        {`What is this based on?`}
      </Text>
      <Text
        fontSize={'1.4rem'}
        mb="1rem"
      >{`It's all about your discretionary income and your credit record`}</Text>
      <Text
        mb="1rem"
        color="brand.1400"
      >{`To find out what kind of vehicle repayment you could reasonably manage, we first need to know what 
      kind of spare money you have left over at the end of the month (once you've dealt with all your other financial 
      responsibilities) This amount is known as your discretionary income and it's the reason we ask for your 
      up-to-date income and expenses info `}</Text>
      <Text
        mb="1rem"
        color="brand.1400"
      >{`Discretionary income = Net monthly income minus total expenses`}</Text>
      <Text
        mb="1rem"
        color="brand.1400"
      >{`Your discretionary income gives us a good indication of how much credit you could afford to take out. 
      But in order to complete the picture, we still need to know how likely you are to pay it back (your credit worthiness). 
      We do this by checking your credit record and credit score.`}</Text>
      <Text
        mb="1rem"
        color="brand.1400"
      >{`Using these two sets of information, we arrive at your estimate affordability. As this information changes, 
      so does your affordability (your credit score is updated every 30 days or so).`}</Text>
      <Text mb="1rem" fontSize={'1.4rem'}>{`But how much will you be approved for?`}</Text>
      <Text color="brand.1400">{`That largely depends on the vehicle itself. Your affordability estimate is a great way to see how the bank views you
      as a potential finance customer, and a powerful piece of information to have when shopping around. 
      But keep in mind that the vehicle's details (equity) play a major role in any vehicle finance application, and can even result
      in your application being declined outright, no matter how high your affordability is.`}</Text>
    </>
  )

  const estimatingAffordabilityStep = (txt: string, description: string) => (
    <Step key={txt}>
      <StepIndicator>
        <StepStatus complete={<StepIcon />} incomplete={<FiDroplet />} active={<FiDroplet />} />
      </StepIndicator>
      <Box flexShrink="0">
        <StepTitle>
          <Text color={currentStep?.() === 0 ? 'primaryDark.500' : 'base.800'}>
            {txt}{' '}
            <Link
              data-testid="affordability-pop-up-link"
              onClick={onOpen}
              variant="unstyled"
              pl="0.6rem"
            >
              <InfoOutlineIcon />
            </Link>
          </Text>
        </StepTitle>
        <StepDescription>{description}</StepDescription>
      </Box>

      <StepSeparator />
    </Step>
  )

  return (
    <Flex flexDir="column" width="100%">
      <VWModal onClose={onClose} size="full" isOpen={isOpen} modalBody={AffordabilityDescription}>
        <Box />
      </VWModal>
      <Steps
        height="500px"
        colorScheme="primaryDark"
        orientation="vertical"
        index={currentStep?.() as number}
        size="md"
      >
        {steps?.map(({ label, description }, i) => {
          if (label === 'Estimating Affordability')
            return estimatingAffordabilityStep(label, description)
          return (
            <Step
              data-testid={`stepper-${label}`}
              key={label}
              {...(isStepLoading && { onClick: () => setCurrentStep?.(i) })}
            >
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<FiDroplet />}
                  active={<FiDroplet />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>
                  <Text
                    data-testid={`step-label-${label}`}
                    color={currentStep?.() === i ? 'primaryDark.500' : 'base.800'}
                  >
                    {label}
                  </Text>
                </StepTitle>
                <StepDescription data-testid={`step-description-${label}`}>
                  {description}
                </StepDescription>
              </Box>
              <StepSeparator />
            </Step>
          )
        })}
      </Steps>
    </Flex>
  )
}
