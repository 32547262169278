import { RepeatIcon } from '@chakra-ui/icons'
import { Avatar, Button, Divider, Flex, Image, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useData } from '../../../context/UserDataProvider'
import images from '../../../theme/images'

const ReloadIndicativeQuote: React.FC = () => {
  const { fetchInsuranceEstimatedCost, loadIndicativeQuote } = useData()

  const handleNakedButton = (): void => {
    fetchInsuranceEstimatedCost?.()
  }

  return (
    <Flex
      flexDirection="column"
      background="base.200"
      border="1px solid #E4E7EC"
      borderRadius="8px"
      width="100%"
      alignSelf="flex-start"
    >
      <Flex
        padding="16px 20px 16px 16px"
        height="64px"
        alignItems="center"
        gap="4px"
        justify="flex-start"
      >
        <Avatar src={images.appIconGroup} />
        <Flex direction={['column', 'row']} ml="10px">
          <Text variant="title" fontSize={['20px', '24px']}>
            Get your instant insurance estimate
          </Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex padding={4} direction="column">
        <Text variant="faint">
          We had some trouble fetching your estimate. Reload to try again, then get your final quote
          without a single phone call.
        </Text>
        <Flex justifyContent="space-between" alignItems="center" mt="24px">
          <Button
            rightIcon={<RepeatIcon />}
            data-testid="naked-reload-button"
            onClick={handleNakedButton}
            isLoading={loadIndicativeQuote}
          >
            Reload estimate
          </Button>
          <Image
            height="52px"
            width="82px"
            objectFit="cover"
            src={images.poweredByNaked}
            alt="Naked"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ReloadIndicativeQuote
