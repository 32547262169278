import { ChevronRightIcon } from '@chakra-ui/icons'
import { Avatar, Button, Divider, Flex, Image, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useData } from '../../../context/UserDataProvider'
import images from '../../../theme/images'

const NoIndicativeQuote: React.FC = () => {
  const { persistedProgress } = useData()

  const savedVehicleData = persistedProgress?.progress?.[1]
  const nakedInsuranceEstimatedCost = persistedProgress?.progress?.naked
  const redirectNakedLink =
    nakedInsuranceEstimatedCost?.reasonForNotProvidingQuote === 'EXISTING_NAKED_USER'
      ? {
          url: nakedInsuranceEstimatedCost?.fallbackLink,
          description:
            'Hop onto the Naked app and get your final quote without a single phone call.',
          buttonLabel: 'Download naked app'
        }
      : {
          url: nakedInsuranceEstimatedCost?.fallbackLink,
          description:
            'Answer a few questions and get your final quote without a single phone call.',
          buttonLabel: 'Get your final price'
        }

  const handleNakedButton = (): void => {
    window.open(redirectNakedLink.url as string, '_blank')
  }
  return (
    <Flex
      flexDirection="column"
      background="#FFFFFF"
      border="1px solid #E4E7EC"
      borderRadius="8px"
      width="100%"
      alignSelf="flex-start"
    >
      <Flex
        padding="16px 20px 16px 16px"
        height="64px"
        alignItems="center"
        gap="4px"
        justify="flex-start"
      >
        <Avatar src={images.appIconGroup} />
        <Flex direction={['column', 'row']} ml="10px">
          <Text variant="title" fontSize={['20px', '24px']}>
            Insure your {savedVehicleData?.make}
          </Text>
          <Text variant="title" fontSize={['20px', '24px']} color="primaryDark.500">
            &nbsp;with Naked
          </Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex padding={4} direction="column">
        <Text variant="faint">
          Heads up! To get financed, you’ll need comprehensive insurance.
          <Text variant="faint" fontWeight={700}>
            {redirectNakedLink.description}
          </Text>
        </Text>
        <Flex justifyContent="space-between" alignItems="center" mt="24px">
          <Button
            rightIcon={<ChevronRightIcon />}
            data-testid="naked-general-redirect-button"
            colorScheme="primaryDark"
            onClick={handleNakedButton}
          >
            {redirectNakedLink.buttonLabel}
          </Button>
          <Image
            height="52px"
            width="82px"
            objectFit="cover"
            src={images.poweredByNaked}
            alt="Naked"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NoIndicativeQuote
