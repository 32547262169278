import { Box, Flex, FlexProps, Radio, RadioGroup } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { useAuth } from '../../context/AuthProvider'
import { RadioInput } from '../../models/RadioInput'

type VWRadioProps<T> = FlexProps & {
  values: RadioInput<T>[]
  setValue: (value: T) => void
  currentValue: T
  padding?: string
  width?: string
  isDisabled?: boolean
}

const VWRadioGroup = <T extends string | undefined>({
  values,
  setValue,
  currentValue,
  padding,
  isDisabled,
  direction = ['column', 'row', 'row']
}: VWRadioProps<T>): ReactElement => {
  const { appName } = useAuth()
  return (
    <RadioGroup onChange={(value) => setValue(value as T)} value={currentValue} width="100%">
      <Flex alignItems={'flex-start'} direction={direction} gap={[6]}>
        {values.map((value) => (
          <Box
            key={value.value}
            padding={padding ? padding : '1rem'}
            borderRadius={appName === 'audifs' ? '0' : '8px'}
            backgroundColor={currentValue === value.value ? 'primary.500' : 'base.300'}
            width={'100%'}
            height="44px"
            justifyContent="center"
            alignItems={'center'}
            color={currentValue === value.value ? 'base.100' : 'primary.500'}
          >
            <Radio
              data-testid={`radio-input-${value.value}`}
              value={value.value}
              width={'100%'}
              height="100%"
              ringOffsetColor={'base.100'}
              colorScheme="base"
              isDisabled={isDisabled}
            >
              {value.message}
            </Radio>
          </Box>
        ))}
      </Flex>
    </RadioGroup>
  )
}

export default VWRadioGroup
