import { Button, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'

type VapsItemCardProps = {
  label: string
  value: string
  isNaked?: boolean
}

export default function VapsItemCard({
  label,
  value,
  isNaked
}: VapsItemCardProps): React.ReactElement {
  return (
    <Flex direction="column" justifyContent="flex-start" my={2}>
      <Flex alignItems="center" gap="20px" justifyContent="space-between">
        <Text data-testid="vaps-row-label" variant="label" fontSize="16px" fontWeight="700">
          {label}
        </Text>
        <Text data-testid="vaps-row-value" variant="label" fontSize="16px" fontWeight="700">
          {value}
        </Text>
      </Flex>
      {isNaked && (
        <Button
          data-testid="dashboard-link"
          color="primaryDark.500"
          variant="link"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          height="20px"
          gap="8px"
          paddingInlineStart={0}
        >
          Powered by Naked
        </Button>
      )}
    </Flex>
  )
}
