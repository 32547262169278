import { Flex, HStack, Heading, Image, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { Logo } from '../../components'
import { useAuth } from '../../context/AuthProvider'

type Props = {
  landingImage: string
  logo: string
  title: string
  subTitle: string
  contentPosition: 'center' | 'flex-start'
  children: React.ReactElement
}

function HomePageLayout({
  landingImage,
  logo,
  title,
  subTitle,
  children,
  contentPosition
}: Props): React.ReactElement {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const { appName } = useAuth()

  return (
    <Flex flexDirection="column" justifyContent="flex-start" flex={1} height="100%">
      <HStack display="flex" justifyContent="space-between" alignItems={contentPosition}>
        <Stack spacing={4} px={{ base: '4%', lg: '12%' }} flex={1} width="50%">
          <Logo
            width="300px"
            ml={appName === 'audifs' ? '0' : '-16px'}
            mt="20px"
            data-testid="landing-page-logo"
            alignSelf="start"
            logo={logo}
          />
          <Stack mt={'1rem'}>
            <Heading data-testid="landing-page-head" fontSize={['3xl', '4xl']}>
              {title}
            </Heading>
            <Text
              data-testid="landing-page-description"
              fontSize={['md', 'lg']}
              variant={'subHeading'}
            >
              {subTitle}
            </Text>
          </Stack>
          {children}
        </Stack>
        {!isMobile && (
          <Flex data-testid="landing-page-image" width="50%">
            <Image src={landingImage} borderLeftRadius={5} flex={1} width="100%" height="100%" />
          </Flex>
        )}
      </HStack>
    </Flex>
  )
}

export default HomePageLayout
