import { ArrowBackIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Highlight,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { omit, round, sum, sumBy, toNumber } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  NakedInfoCard,
  SelectedProductRow,
  UploadDocumentsButton,
  VapsItemCard
} from '../../components'
import DataInfoCard from '../../components/DataInfoCard'
import InfoRowCard from '../../components/InfoRowCard'
import VWModal from '../../components/Modal'
import RemoveVAPsModal from '../../components/Modal/RemoveVAPsModal'
import Products, { SelectedProduct } from '../../components/Products'
import { useAppContext } from '../../context/AppProvider'
import { useAuth } from '../../context/AuthProvider'
import { useData } from '../../context/UserDataProvider'
import { Product, useSaveUserProductsMutation } from '../../generated'
import { calculateProductPrice, IN_PROGRESS_FINANCE_APPLICATION_KEY } from '../../utils'
import formatFinanceAmount from '../../utils/math/format/formatFinanceAmount'
import { FinanceDetails } from '../../utils/types'

// Todo: delete this object after development
const xFinanceDetails = {
  amountToBeFinanced: '700500',
  monthlyInstallment: '10000',
  deposit: '250500',
  term: '24',
  balloonPerc: '12',
  initiationFee: '2783'
}

function ApprovalScreen(): React.ReactElement {
  const navigate = useNavigate()
  const { financeData, setPersistedProgress, persistedProgress } = useData()
  const { aboutVehicle } = useAppContext()
  const { user, appName, baseURL } = useAuth()
  const vafResponse = JSON.parse(financeData ? financeData : JSON.stringify({}))
  // Todo: delete this operator after development
  const financeDetails: FinanceDetails = vafResponse.financeDetails ?? xFinanceDetails
  const referenceNumber = vafResponse?.financeIdentifiers?.referenceNumber ?? '#£'
  const savedVehicleData = persistedProgress?.progress?.[1]
  const vehicle = `${savedVehicleData?.make ?? aboutVehicle.make} ${savedVehicleData?.model ?? aboutVehicle.model}`
  const amountToFinance = financeDetails.amountToBeFinanced
  const monthlyInstallment = financeDetails.monthlyInstallment
  const deposit = financeDetails.deposit
  const paymentTerm = financeDetails.term
  const balloonPayment = financeDetails.balloonPerc
  const initialFee = financeDetails.initiationFee
  const nakedInsuranceEstimatedCost = persistedProgress?.progress?.naked?.indicativeQuote
  const [selectedProduct, setSelectedProduct] = React.useState<SelectedProduct>()
  const [removedProduct, setRemovedProduct] = React.useState<string>('')
  const {
    isOpen: isOpenRemoveModal,
    onOpen: onOpenRemoveModal,
    onClose: onCloseRemoveModal
  } = useDisclosure()

  const handleRemoveProduct = (category: string) => () => {
    setRemovedProduct(category)
    onOpenRemoveModal()
  }

  const handleSelectProduct = (category: string, product: Product) => () => {
    setSelectedProduct({ ...selectedProduct, [`${category}`]: product })
  }

  const removeSelectedProduct = (category: string) => () => {
    const newProducts = omit(selectedProduct, [category])
    setSelectedProduct(newProducts)
    onCloseRemoveModal()
  }

  const toast = useToast()

  const [saveUserProducts, { loading: isSavingUserProducts }] = useSaveUserProductsMutation({
    onError: () => {
      toast({
        title: 'Error!',
        description:
          'We are currently experiencing a high volume of traffic. Kindly submit the products again. Thank you for your patience.',
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    },
    onCompleted: (res) => {
      toast({
        title: 'VAPs products submitted successfully!',
        description: res.saveUserProducts,
        status: 'success',
        duration: 9000,
        isClosable: true,
        colorScheme: 'primaryDark'
      })
      navigate(`${baseURL}auth/dashboard`)
      setSelectedProduct(undefined)
    }
  })

  const handleSubmitProducts = async () => {
    await saveUserProducts({
      variables: {
        input: {
          products: Object.values(selectedProduct ?? {}).map((product) => {
            return {
              id: product?.id as string,
              longName: product?.longName as string
            }
          }),
          dealReferenceNumber: referenceNumber,
          userId: user?.id as string,
          appName: appName as string
        }
      }
    })
  }

  const totalEstimatedCost = React.useMemo(() => {
    const nakedEstimatedCost = (nakedInsuranceEstimatedCost?.valueInCents ?? 0) / 100
    const vapsTotalCost = sumBy(Object.values(selectedProduct ?? {}), function (product) {
      return toNumber(product?.pricingValue) > 0
        ? round(toNumber(product?.pricingValue))
        : calculateProductPrice(amountToFinance, product?.pricingValue ?? '')
    })
    return sum([Number(monthlyInstallment), nakedEstimatedCost, Number(vapsTotalCost)])
  }, [selectedProduct])

  const handleBackButton = (): void => {
    navigate(`${baseURL}auth/dashboard`)
    setPersistedProgress?.(undefined)
    sessionStorage.removeItem(IN_PROGRESS_FINANCE_APPLICATION_KEY)
  }

  return (
    <Flex flexDirection="column" padding="3%">
      <VWModal
        showFooter={false}
        onClose={onCloseRemoveModal}
        size="full"
        isOpen={isOpenRemoveModal}
        modalBody={
          <RemoveVAPsModal
            removeHandler={removeSelectedProduct(removedProduct)}
            onClose={onCloseRemoveModal}
            productName={selectedProduct?.[removedProduct]?.shortName ?? ''}
          />
        }
      >
        <Box />
      </VWModal>
      <Stack>
        <Flex direction="column">
          <Flex justify="flex-start" mt="1.5rem">
            <Button
              data-testid="dashboard-link"
              color="primaryDark.500"
              variant="link"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              height="20px"
              gap="8px"
              p={0}
              onClick={handleBackButton}
              paddingInlineStart={0}
            >
              <ArrowBackIcon />
              Back to dashboard
            </Button>
          </Flex>
          <Flex justifyContent="flex-start" width="100%" mt="32px" my="16px">
            <Flex
              borderRadius={16}
              bg="base.100"
              justifyContent="space-between"
              width={['100%', '20%']}
            >
              <Badge
                alignSelf="end"
                padding=" 2px 10px"
                borderRadius="16px"
                display="flex"
                height="32px"
                alignItems="center"
                justifyContent="center"
                color="white"
                background="success.500"
              >
                APPROVED
              </Badge>
              <Flex justify="center" alignItems="center" pr={4}>
                <Text variant="faint">Deal no:</Text>
                <Text variant="label" fontSize="16px" fontWeight="700">
                  {referenceNumber}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify="flex-start" direction="column" mb={4}>
          <Text variant="title" size="xl">
            {vehicle}
          </Text>
          <Flex flexDirection={['column', 'row']} justify="flex-start" mt="16px">
            <Text variant="faint">
              <Highlight
                query="Our team will reach out with next steps."
                styles={{ fontWeight: 700, color: 'primaryDark.900' }}
              >
                Our team will reach out with next steps. In the meantime, upload your documents and
                explore our products to make your journey to ownership even smoother.
              </Highlight>
            </Text>
          </Flex>
        </Flex>
        <Divider />
        <Flex
          flexWrap="wrap"
          justify={['normal', 'normal', 'space-between']}
          width="100%"
          py={2}
          mt={4}
        >
          <Flex direction="column" width={['100%', '100%', '55%']}>
            <DataInfoCard
              monthlyInstallment={`R ${formatFinanceAmount(monthlyInstallment.toString())}`}
              approval
            >
              <Flex flexWrap="wrap" justifyContent={['normal', 'normal', 'space-between']}>
                <Flex flexDirection="column" padding="16px" gap="4px" alignItems="flex-start">
                  <InfoRowCard
                    label="Finance Amount"
                    value={`R ${formatFinanceAmount(amountToFinance.toString())}`}
                  />
                  <InfoRowCard
                    label="Deposit"
                    value={`R ${formatFinanceAmount(deposit.toString())}`}
                  />
                  <InfoRowCard label="Payment Term" value={`${paymentTerm} Months`} />
                </Flex>

                <Flex flexDirection="column" padding="16px" gap="4px" alignItems="flex-start">
                  <InfoRowCard
                    label="Initial Fee"
                    value={`R ${formatFinanceAmount(initialFee.toString())}`}
                  />
                  <InfoRowCard
                    label="Balloon Payment"
                    value={`R ${formatFinanceAmount(balloonPayment.toString())}`}
                  />
                </Flex>
                <Flex justifyContent="flex-end" flexDirection="column" padding={4}>
                  <UploadDocumentsButton />
                </Flex>
              </Flex>
            </DataInfoCard>
            <NakedInfoCard mt={4} />
            <Stack px={4} py={2}>
              <Text fontSize={['20px', '24px']}>Value-Added Products</Text>
              <Text variant="faint">
                Enquire about our wide array of products to assist you on your journey to ownership.
              </Text>
            </Stack>
            <Products
              financeAmount={amountToFinance}
              selectedProduct={selectedProduct ?? {}}
              handleSelectProduct={handleSelectProduct}
            />
          </Flex>
          <Flex
            flexDirection="column"
            width={['100%', '100%', '44%']}
            height="auto"
            alignSelf="flex-start"
            shadow="none"
            marginY={[4, 4, 0]}
            padding="16px 20px 16px 16px"
          >
            <Flex justifyContent="space-between" gap="4px">
              <Text fontSize={['20px', '24px']}>Estimated Cost of Ownership</Text>
              <Text variant="label" fontWeight={700} color="primaryDark.900" ml={2}>
                R {formatFinanceAmount(totalEstimatedCost.toString())}
              </Text>
            </Flex>
            <Divider my={2} />
            <Flex direction="column">
              <VapsItemCard
                label="Monthly Installment"
                value={`R ${formatFinanceAmount(monthlyInstallment.toString())}`}
              />
              {nakedInsuranceEstimatedCost && (
                <VapsItemCard
                  label="Comprehensive insurance"
                  value={`R ${formatFinanceAmount(((nakedInsuranceEstimatedCost?.valueInCents ?? 0) / 100).toString())}*`}
                  isNaked
                />
              )}

              <Divider my={4} />
              {Object.values(selectedProduct ?? {}).length === 0 && (
                <Text fontSize={12} fontWeight={700} color="base.500">
                  No Value-Added Products selected
                </Text>
              )}
              {Object.values(selectedProduct ?? {}).length > 0 && (
                <>
                  <Text fontSize={12} fontWeight={700} color="base.500">
                    Value-Added Products
                  </Text>
                  {Object.keys(selectedProduct ?? {}).map((category) => {
                    const product = selectedProduct?.[category]
                    return (
                      <SelectedProductRow
                        key={product?.id}
                        label={product?.shortName ?? ''}
                        value={`R ${
                          toNumber(product?.pricingValue) > 0
                            ? round(toNumber(product?.pricingValue))
                            : calculateProductPrice(amountToFinance, product?.pricingValue ?? '')
                        }`}
                        onDelete={handleRemoveProduct(category)}
                      />
                    )
                  })}
                  <Divider my={4} />
                  <Flex justifyContent="flex-end">
                    <Button
                      data-testid="dashboard-link"
                      rightIcon={<ChevronRightIcon />}
                      variant="outline"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleSubmitProducts}
                      isLoading={isSavingUserProducts}
                    >
                      I am interested
                    </Button>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Stack>
    </Flex>
  )
}

export default ApprovalScreen
