import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'
import { useData } from '../../context/UserDataProvider'
import IndicativeQuote from './IndicativeQuote'
import NoIndicativeQuote from './NoIndicativeQuote'
import ReloadIndicativeQuote from './ReloadIndicativeQuote'

type NakedInfoCardProps = FlexProps

export default function NakedInfoCard({ ...rest }: NakedInfoCardProps): React.ReactElement {
  const { persistedProgress } = useData()

  const nakedInsuranceEstimatedCost = persistedProgress?.progress?.naked
  const content = React.useMemo(() => {
    if (nakedInsuranceEstimatedCost?.indicativeQuote) {
      return <IndicativeQuote />
    } else if (nakedInsuranceEstimatedCost?.reasonForNotProvidingQuote) {
      return <NoIndicativeQuote />
    } else {
      return <ReloadIndicativeQuote />
    }
  }, [persistedProgress])

  return <Flex {...rest}>{content}</Flex>
}
