import { WarningIcon } from '@chakra-ui/icons'
import { Avatar, Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IdleTimerProvider, useIdleTimerContext } from 'react-idle-timer'
import VWModal from '../../components/Modal'
import { useAuth } from '../../context/AuthProvider'

const TIMEOUT_DURATION = 1000 * 60 * 30
const PROMPT_BEFORE_IDDLE = 1000 * 60 * 5
const THROTTLE_DURATION = 500

function Prompt() {
  const [remaining, setRemaining] = useState<number>(0)

  const { activate, getRemainingTime } = useIdleTimerContext()

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <Flex alignItems="center" p="24px" flexDirection="column" alignSelf="center" gap="32px">
      <Avatar icon={<WarningIcon color="white" />} backgroundColor="base.50" />
      <Text variant="title" fontSize="16px" textAlign="center">
        Are you still here?
      </Text>
      <p>Logging out in {remaining} seconds</p>
      <Button onClick={activate}>Im still here</Button>
    </Flex>
  )
}

type SessionProviderProps = {
  children?: React.ReactElement
}

const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { logout } = useAuth()

  const onPrompt = () => {
    setOpen(true)
  }

  const onIdle = () => {
    setOpen(false)
    logout?.()
  }

  const onActive = () => {
    setOpen(false)
  }

  return (
    <IdleTimerProvider
      timeout={TIMEOUT_DURATION}
      promptBeforeIdle={PROMPT_BEFORE_IDDLE}
      throttle={THROTTLE_DURATION}
      onPrompt={onPrompt}
      onIdle={onIdle}
      onActive={onActive}
    >
      <VWModal
        showFooter={false}
        onClose={() => setOpen(false)}
        size="full"
        isOpen={open}
        modalBody={<Prompt />}
      >
        <Box />
      </VWModal>
      {children}
    </IdleTimerProvider>
  )
}

export default SessionProvider
