import { round, toNumber } from 'lodash'
import { ADMIN_STORAGE_KEY, USER_STORAGE_KEY } from '../constants'
export function isValidEmail(email: string) {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
  return emailRegex.test(email)
}
/**
 * The function formats a phone number by adding a leading zero if the length is greater than 10.
 * @param {string} phone - The `phone` parameter is a string representing a phone number.
 * @returns the formatted phone number as a string. If the original phone number is longer than 10
 * digits, it will slice the last 9 digits and add a "0" at the beginning before returning the
 * formatted phone number.
 */
export function formatPhoneNumber(phone?: string) {
  if (!phone) return
  const length = phone.length
  if (length > 10) {
    phone = `0${phone.slice(length - 9)}`
  }
  return phone
}

export const fetchJwt = (): string | null => {
  const localUser =
    localStorage.getItem(USER_STORAGE_KEY) ?? localStorage.getItem(ADMIN_STORAGE_KEY)
  const sessionUser =
    sessionStorage.getItem(USER_STORAGE_KEY) ?? sessionStorage.getItem(ADMIN_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user).jwt : null
}

export const padDigit = (digit: number, pad: number) => {
  return String(digit).padStart(pad, '0')
}

export const genderToGenderData = (gender: string) => {
  const binary = gender.startsWith('m') ? 1 : 0
  return {
    title: binary > 0 ? 'MR' : 'MRS',
    gender: binary > 0 ? 'M' : 'F'
  }
}

export const getPhoneNumber = (phone: string) => {
  const length = phone.length
  if (length > 10) {
    phone = `0${phone.slice(length - 9)}`
  }
  return phone
}

export const getMakeAndModelCodes = (mmcode: string) => {
  return {
    makeCode: mmcode.substring(0, 3),
    modelCode: mmcode.substring(3)
  }
}

export const capitalize = (label?: string) => {
  return label ? label[0].toUpperCase() + label.slice(1).toLowerCase() : ''
}

export const calculateProductPrice = (financeAamount: number, priceValue: string) => {
  const pValue = priceValue.match(/[\d\.]+/g)

  return round((financeAamount / 1000) * toNumber(pValue?.[1]))
}

const PHONE_NUMBER_CODE = '+27'

export const formatUserNumberToInternationalFormat = (userNumber: string): string => {
  return `${PHONE_NUMBER_CODE}${userNumber.slice(1)}`
}
