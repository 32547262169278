import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Spinner,
  Stack,
  Text,
  useMediaQuery
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { SAIdRegx } from '../../components/Form/LoginForm'
import {
  AdminAuthPayload,
  AuthenticationResponse,
  AuthPayload,
  LoginInput,
  VerifyOtpInput
} from '../../generated'

type Props = {
  landingImage: string
  verifyOtp?: (input: VerifyOtpInput) => Promise<AdminAuthPayload | AuthPayload>
  login?: (input: LoginInput) => Promise<AuthenticationResponse>
  deleteOtp?: (input: LoginInput) => void
  appName?: string
  baseURL: string
}

const OTPScreen: React.FC<Props> = ({
  landingImage,
  verifyOtp,
  login,
  appName,
  baseURL,
  deleteOtp
}) => {
  const [otp, setOtp] = useState<string>('')
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(30)
  const [error, setError] = useState<string | null>(null)
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)
  const [isResendingOTP, setIsResendingOTP] = useState<boolean>(false)
  const [isMobile] = useMediaQuery('(max-width: 800px)')

  const navigate = useNavigate()
  const { id } = useParams()

  const loginInput = React.useMemo(() => {
    const input: LoginInput = {}
    if (id?.match(SAIdRegx)) {
      input.idNumber = id
    } else {
      input.passportNumber = id
    }

    return input
  }, [id])

  useEffect(() => {
    if (!id) navigate(baseURL ?? '/')
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  useEffect(() => {
    const clearOTP = async () => {
      await deleteOtp?.(loginInput)
    }

    if (seconds === 0) {
      clearOTP()
    }
  }, [seconds])

  const handleSubmit = async (): Promise<void> => {
    setIsAuthenticating(true)
    const otpData: VerifyOtpInput = { ...loginInput, otp }

    if (!otp) {
      setError('Please enter a valid otp')
      setIsAuthenticating(false)
      return
    }

    const res = await verifyOtp?.(otpData)

    if (res?.error) {
      setError(res.error)
      setIsAuthenticating(false)
    }
  }

  const resendOTP = async () => {
    setIsResendingOTP(true)
    setError('')
    setOtp('')
    setSeconds(30)
    setMinutes(1)

    try {
      login && (await login(loginInput))
    } catch (err) {
      const e = (err as Error).message
      setError('There was an error resending otp: ' + e)
      setIsResendingOTP(false)
    }

    setIsResendingOTP(false)
  }

  return (
    <HStack justify={'center'} align="center">
      <Box padding={'1rem'} margin={8} flex={1} px={{ base: '4%', lg: '12%' }} width="50%">
        <Heading data-testid="otpscreen-head" mb={'1rem'} textStyle="h2">
          Verify your phone number
        </Heading>
        <Text data-testid="otpscreen-description" pb="2rem" variant={'subHeading'}>
          Please enter the 6-digit OTP sent to the phone number you provided
        </Text>
        <Text data-testid="otpscreen-code-label" fontSize={'0.8rem'}>
          Secure Code
        </Text>
        <HStack paddingBottom={8} justify="center" color="brand.1100">
          <PinInput
            otp
            value={otp}
            onChange={(value) => setOtp(value)}
            placeholder="0"
            colorScheme={'brand.1100'}
            size="lg"
            autoFocus
          >
            {[...Array(3).keys()].map((x) => {
              return (
                <PinInputField
                  data-testid={`left-side-pin-${x}`}
                  key={x}
                  width={['11vw', '9vw', '8vw', '5vw']}
                  height={['11vw', '9vw', '8vw', '5vw']}
                  color="brand.1400"
                  fontSize={'3xl'}
                  borderRadius={appName === 'audifs' ? '0' : '8px'}
                />
              )
            })}
            {
              <Text color="brand.1300" fontSize={'2rem'}>
                -
              </Text>
            }
            {[...Array(3).keys()].map((x) => {
              return (
                <PinInputField
                  data-testid={`right-side-pin-${x}`}
                  key={x}
                  width={['11vw', '9vw', '8vw', '5vw']}
                  height={['11vw', '9vw', '8vw', '5vw']}
                  color="brand.1400"
                  fontSize={'3xl'}
                  borderRadius={appName === 'audifs' ? '0' : '8px'}
                />
              )
            })}
          </PinInput>
        </HStack>
        <Stack align="center" justify="center">
          <Button
            data-testid="verify-otp-button"
            type="submit"
            isLoading={isAuthenticating}
            onClick={handleSubmit}
            width="100%"
            mt={'3'}
          >
            Submit
          </Button>
          <Text
            data-testid="otpscreen-error-message"
            height="1.5rem"
            color={'red.500'}
            paddingTop={5}
            pb="1.5rem"
            align="center"
          >
            {error ?? error}
          </Text>
        </Stack>

        <HStack align={'center'} justify="center">
          {seconds > 0 || minutes > 0 ? (
            <Text data-testid="otpscreen-time-count-down" color="brand.1200">
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </Text>
          ) : (
            <Text data-testid="otpscreen-resend-code-alert" color="brand.1200">
              Didn&apos;t receive the code?
            </Text>
          )}
          <Button
            data-testid="otpscreen-resend-otp-button"
            isDisabled={seconds > 0 || minutes > 0}
            onClick={resendOTP}
            variant="callToAction"
          >
            {isResendingOTP ? <Spinner /> : 'Resend OTP'}
          </Button>
        </HStack>
        <Stack width="100%" justify={'center'} align="center">
          <Button
            data-testid="otpscreen-login-page-button"
            isDisabled={seconds > 0 || minutes > 0}
            onClick={() => navigate('/login')}
            variant="callToAction"
          >
            <HStack>
              <ArrowBackIcon />
              <Text fontWeight={'medium'} fontSize="0.85rem">
                Back to Login
              </Text>
            </HStack>
          </Button>
        </Stack>
      </Box>
      {!isMobile && (
        <Flex width="50%">
          <Image
            data-testid="otpscreen-landing-image"
            src={landingImage}
            borderLeftRadius={5}
            flex={1}
            width="100%"
            height="100%"
          />
        </Flex>
      )}
    </HStack>
  )
}

export default OTPScreen
