import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import { round, toNumber } from 'lodash'
import * as React from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { useAuth } from '../../context/AuthProvider'
import {
  Product,
  ProductCategoryPayload,
  useGetAllProductCategoriesLazyQuery
} from '../../generated'
import { calculateProductPrice } from '../../utils'

export type SelectedProduct = { [key in string]?: Product }

type Props = {
  financeAmount: number
  selectedProduct: SelectedProduct
  handleSelectProduct: (category: string, product: Product) => () => void
}

const Products: React.FC<Props> = ({ financeAmount, selectedProduct, handleSelectProduct }) => {
  const { appName } = useAuth()
  const [categories, setCategories] = React.useState<ProductCategoryPayload[]>()
  const [getAllProductCategories, { loading: loadCategories }] =
    useGetAllProductCategoriesLazyQuery({
      onCompleted: (res) => {
        setCategories(res.getAllProductCategories as ProductCategoryPayload[])
      }
    })

  React.useEffect(() => {
    getAllProductCategories({
      variables: {
        filter: {
          searchTerm: '',
          make: appName === 'vwfs' ? 'VW' : 'AUDI'
        }
      }
    })
  }, [])

  return (
    <Flex pt={4}>
      <Flex
        flexDirection="column"
        background="#FFFFFF"
        border="1px solid #E4E7EC"
        borderRadius="8px"
        width="100%"
        alignSelf="flex-start"
        data-testid="vaps-products-container"
      >
        <Flex justify="center" alignItems="center">
          {loadCategories && <Spinner />}
        </Flex>
        <Accordion allowToggle>
          {categories?.map((category) => (
            <AccordionItem key={category.id}>
              <AccordionButton
                data-testid="vaps-category-title"
                fontSize={['md', 'lg']}
                fontWeight={700}
                color="primaryDark.900"
              >
                <Box as="span" flex="1" textAlign="left">
                  {category.name}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Flex direction="column" width="100%">
                  <Flex direction="column" width="100%">
                    <Text
                      data-testid="landing-page-description"
                      fontSize={14}
                      mb={4}
                      fontWeight={300}
                    >
                      {category.description}
                    </Text>
                    <Popover placement="top-end">
                      <PopoverTrigger>
                        <Button
                          data-testid="dashboard-link"
                          color="primaryDark.500"
                          variant="link"
                          display="flex"
                          flexDirection="row"
                          alignItems="left"
                          justifyContent="flex-left"
                          height="20px"
                          gap="8px"
                          mt="1.5rem"
                          width="10%"
                          px={0}
                          fontWeight={500}
                          fontSize={16}
                          rightIcon={<FiAlertCircle />}
                        >
                          Disclaimer
                        </Button>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverHeader fontWeight={600}>Category disclaimer</PopoverHeader>
                          <PopoverCloseButton />
                          <PopoverBody>
                            <UnorderedList>
                              {category.disclaimers?.map((disclaimer) => (
                                <ListItem key={disclaimer?.id}>{disclaimer?.description}</ListItem>
                              ))}
                            </UnorderedList>
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  </Flex>
                  <Flex direction="column" width="100%">
                    <Stack>
                      <Accordion pt={2} allowToggle>
                        {category.products?.map((product) => (
                          <AccordionItem key={product?.id}>
                            <AccordionButton
                              _expanded={{ bg: 'base.200' }}
                              justifyContent="space-between"
                            >
                              <Box as="span" flex="1" textAlign="left">
                                <Text
                                  data-testid="landing-page-description"
                                  fontSize={['md', '15px']}
                                  variant={'subHeading'}
                                  fontWeight={600}
                                  color="primaryDark.900"
                                >
                                  {product?.shortName}
                                </Text>
                              </Box>
                              <Flex justifyContent="flex-end" alignItems="center">
                                <Text fontSize="md" color="primaryDark.700" fontWeight={700}>
                                  R{' '}
                                  {toNumber(product?.pricingValue) > 0
                                    ? round(toNumber(product?.pricingValue))
                                    : calculateProductPrice(
                                        financeAmount,
                                        product?.pricingValue ?? ''
                                      )}
                                </Text>
                                <Text ml={2} color="primaryDark.700" fontSize="md">
                                  {product?.pricingWord}
                                </Text>
                              </Flex>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              <Flex direction="column">
                                <>
                                  {product?.benefits?.length === 1 ? (
                                    <Text fontSize={14} fontWeight={400} color="primaryDark.900">
                                      {product?.benefits?.[0]?.name}
                                    </Text>
                                  ) : (
                                    <UnorderedList>
                                      {product?.benefits?.map((benefit) => (
                                        <ListItem
                                          key={benefit?.id}
                                          fontSize={14}
                                          fontWeight={400}
                                          color="primaryDark.900"
                                        >
                                          {benefit?.name}
                                        </ListItem>
                                      ))}
                                    </UnorderedList>
                                  )}
                                </>
                                <Flex justify="flex-start" alignItems="center" width="39%">
                                  <Checkbox
                                    isChecked={product?.id === selectedProduct[category.name]?.id}
                                    size="md"
                                    color="primaryDark.500"
                                    fontWeight={700}
                                    defaultChecked
                                    onChange={handleSelectProduct(
                                      category.name,
                                      product as Product
                                    )}
                                  >
                                    I would like to know more
                                  </Checkbox>
                                </Flex>
                              </Flex>
                            </AccordionPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </Stack>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Flex>
  )
}

export default Products
