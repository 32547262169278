import { ChevronRightIcon } from '@chakra-ui/icons'
import { Avatar, Badge, Button, Divider, Flex, Image, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useData } from '../../../context/UserDataProvider'
import images from '../../../theme/images'
import formatFinanceAmount from '../../../utils/math/format/formatFinanceAmount'
import { useTrackGetFinalPriceEventMutation } from '../../../generated'
import { useAuth } from '../../../context/AuthProvider'

const IndicativeQuote: React.FC = () => {
  const { persistedProgress } = useData()
  const { user } = useAuth()

  const savedVehicleData = persistedProgress?.progress?.[1]
  const nakedInsuranceEstimatedCost = persistedProgress?.progress?.naked?.indicativeQuote
  const [trackGetFinalPriceEvent] = useTrackGetFinalPriceEventMutation()

  const handleNakedButton = async () => {
    await trackGetFinalPriceEvent({
      variables: {
        input: {
          userId: user?.id as string,
          estimatedCostInCents: nakedInsuranceEstimatedCost?.valueInCents,
          indicativeQuoteId: nakedInsuranceEstimatedCost?.indicativeQuoteId
        }
      }
    })
    window.open(nakedInsuranceEstimatedCost?.linkToCompleteQuote as string, '_blank')
  }
  return (
    <Flex
      flexDirection="column"
      background="#FFFFFF"
      border="1px solid #E4E7EC"
      borderRadius="8px"
      width="100%"
      alignSelf="flex-start"
    >
      <Flex
        padding="16px 20px 16px 16px"
        height="64px"
        alignItems="center"
        gap="4px"
        justify="space-between"
      >
        <Flex justify="flex-start" alignItems="center">
          <Avatar src={images.appIconGroup} />
          <Flex direction={['column', 'row']} ml="10px">
            <Text variant="title" fontSize={['20px', '24px']}>
              Insure your {savedVehicleData?.make} for&nbsp;
            </Text>
            <Text variant="title" fontSize={['20px', '24px']} color="primaryDark.500">
              {`R ${formatFinanceAmount((nakedInsuranceEstimatedCost?.valueInCents / 100).toString())}pm*`}
            </Text>
          </Flex>
        </Flex>

        <Badge
          alignSelf="end"
          padding=" 2px 10px"
          borderRadius="16px"
          display="flex"
          height="24px"
          alignItems="center"
          justifyContent="center"
          color="base.900"
          background="base.200"
        >
          ESTIMATED*
        </Badge>
      </Flex>
      <Divider />
      <Flex padding={4} direction="column">
        <Text variant="faint">
          Heads up! To get financed, you’ll need comprehensive insurance.
          <Text variant="faint" fontWeight={700}>
            Answer a few questions and get your final quote without a single phone call.
          </Text>
        </Text>
        <Flex justifyContent="space-between" alignItems="center" mt="24px">
          <Button
            rightIcon={<ChevronRightIcon />}
            data-testid="naked-custom-redirect-button"
            colorScheme="primaryDark"
            onClick={handleNakedButton}
          >
            Get your final price
          </Button>
          <Image
            height="52px"
            width="82px"
            objectFit="cover"
            src={images.poweredByNaked}
            alt="Naked"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default IndicativeQuote
