export default {
  bg: new URL('../assets/images/core-suite-bg.jpeg', import.meta.url).href,
  sovtech: new URL('../assets/images/sovtech.png', import.meta.url).href,
  sovtechSmall: new URL('../assets/images/sovtech-small.png', import.meta.url).href,
  404: new URL('../assets/images/404.svg', import.meta.url).href,
  noData: new URL('../assets/images/no-data.png', import.meta.url).href,
  bottom: new URL('../assets/map/bottom.png', import.meta.url).href,
  halfBottom: new URL('../assets/map/half-bottom.png', import.meta.url).href,
  halfMidNorth: new URL('../assets/map/half-mid-north.png', import.meta.url).href,
  halfMidSouth: new URL('../assets/map/half-mid-south.png', import.meta.url).href,
  halfTop: new URL('../assets/map/half-top.png', import.meta.url).href,
  midOne: new URL('../assets/map/mid1.png', import.meta.url).href,
  midTwo: new URL('../assets/map/mid2.png', import.meta.url).href,
  midThree: new URL('../assets/map/mid3.png', import.meta.url).href,
  top: new URL('../assets/map/top.png', import.meta.url).href,
  coreSuiteDark: new URL('../assets/images/core-suite-FE-dark.svg', import.meta.url).href,
  coreSuiteLight: new URL('../assets/images/core-suite-FE-light.svg', import.meta.url).href,
  walkthroughOne: new URL('../assets/walkthrough/walkthrough-one.jpg', import.meta.url).href,
  walkthroughTwo: new URL('../assets/walkthrough/walkthrough-two.jpg', import.meta.url).href,
  walkthroughThree: new URL('../assets/walkthrough/walkthrough-three.jpg', import.meta.url).href,
  walkthroughFour: new URL('../assets/walkthrough/walkthrough-four.jpg', import.meta.url).href,
  walkthroughFive: new URL('../assets/walkthrough/walkthrough-five.jpg', import.meta.url).href,
  vwLogo: new URL('../assets/images/vw-logo.png', import.meta.url).href,
  car: new URL('../assets/images/car.jpeg', import.meta.url).href,
  audiLogo: new URL('../assets/images/audi-logo.png', import.meta.url).href,
  carIcon: new URL('../assets/images/car_icon.svg', import.meta.url).href,
  errorIcon: new URL('../assets/images/error.svg', import.meta.url).href,
  vwLoginImg: new URL('../assets/images/new-vw-logo.svg', import.meta.url).href,
  vwfsLogo: new URL('../assets/images/vwfsLogo.svg', import.meta.url).href,
  smileySad: new URL('../assets/images/smileySad.svg', import.meta.url).href,
  appIconGroup: new URL('../assets/images/appIconGroup.svg', import.meta.url).href,
  poweredByNaked: new URL('../assets/images/poweredByNaked_logo.svg', import.meta.url).href
}
